export default (error_list, error, remove = false, product_id) => {
  const is_error_in_list = error_list.find(({ type, id }) => {
    if (product_id) return type === error.type && id === product_id
    return type === error.type
  })

  if (is_error_in_list && remove) return error_list.filter(({ type }) => type !== error.type && id !== product_id)
  else if (!is_error_in_list) return [...error_list, error]

  return error_list
}
