import { mapMutations } from "vuex"
import { UPDATE_ERROR_LIST, CLEAR_ERRORS_SECTION, UPDATE_ERROR_LIST_TITLE } from "../../stores/Admin/constants"
import store from "../../../../store"
import { ADMIN, PRODUCTS } from "../../constants/others_constants"

export default (store_name) => ({
  ...mapMutations(store_name, {
    update_errors: UPDATE_ERROR_LIST,
    clear_errors: CLEAR_ERRORS_SECTION,
    update_error_list_title: UPDATE_ERROR_LIST_TITLE
  }),
  get_error_list() {
    return store.state[ADMIN].error_list
  },
  get_error_products_list() {
    return store.state[ADMIN][PRODUCTS].error_list
  }
})
